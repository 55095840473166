exports.STORAGE_URL = 'https://mpaper.s3.us-west-2.amazonaws.com/';

// exports.AUTH_URL = 'http://localhost:5000/api/users/auth/';
// exports.PUBLISHER_URL = 'http://localhost:5001/api/publishers/';
// exports.USERS_URL = 'http://localhost:5000/api/users/';
// exports.CORPORATE_URL = 'http://localhost:5005/api/corporates/';
// exports.PROMOTION_URL = 'http://localhost:5004/api/promotions/';
// exports.BILLING_URL = 'https://billing.sandbox.rifaly.com/';

exports.AUTH_URL = 'https://api.sandbox.rifaly.com/api/users/auth';
exports.PUBLISHER_URL = 'https://api.sandbox.rifaly.com/api/publishers/';
exports.USERS_URL = 'https://api.sandbox.rifaly.com/api/users/';
exports.CORPORATE_URL = 'https://api.sandbox.rifaly.com/api/corporates/';
exports.PROMOTION_URL = 'https://api.sandbox.rifaly.com/api/promotions/';
exports.BILLING_URL = 'https://billing.sandbox.rifaly.com/';
exports.EMAIL_URL = 'https://api.sandbox.rifaly.com/api/emails/';
exports.AUDIO_URL = 'https://api.sandbox.rifaly.com/api/audio/';

// exports.AUTH_URL = 'https://api.rifaly.co.tz/api/users/auth';
// exports.PUBLISHER_URL = 'https://api.rifaly.co.tz/api/publishers/';
// exports.CORPORATE_URL = 'https://api.rifaly.co.tz/api/corporates/';
// exports.PROMOTION_URL = 'https://api.rifaly.co.tz/api/promotions/';
// exports.USERS_URL = 'https://api.rifaly.co.tz/api/users/';
// exports.BILLING_URL = 'https://billing.rifaly.co.tz/';
// exports.COUNTRIES_URL = 'https://billing.rifaly.co.tz/billing/corporates/';
// exports.EMAIL_URL = 'https://api.rifaly.co.tz/api/emails/';
// exports.AUDIO_URL = 'https://api.rifaly.co.tz/api/audio/';
